import React, { useEffect, useState } from 'react'
import Footer from './footer'
import whatsappIcon from '../images/whatsapp-icon.png'

const Layout = ({ lang, children }) => {
    const [whatsAppHref, setWhatsAppHref] = useState()

    useEffect(() => {
        if (lang === 'es') {
            setWhatsAppHref(
                'https://wa.me/5491152351034?text=Hola%2C%20quiero%20m%C3%A1s%20informaci%C3%B3n%20sobre%20Optiwe%21'
            )
        } else if (lang === 'en') {
            setWhatsAppHref(
                'https://wa.me/14157040340?text=Hello%2C%20I%20want%20to%20know%20more%20about%20Optiwe%21'
            )
        }
    }, [lang])

    return (
        <>
            {children}
            <a href={whatsAppHref} className="whatsapp" target="_blank" rel="noreferrer">
                {' '}
                <img src={whatsappIcon} className="whatsapp-icon opt-whatsapp-icon" alt="WhatsApp icon" />
            </a>
            <Footer lang={lang}></Footer>
        </>
    )
}

export default Layout
