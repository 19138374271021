/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

function SEO ({ description, lang, meta, title, locales }) {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        author
                    }
                }
            }
        `
    )

    const metaDescription = description

    return (
        <Helmet
            htmlAttributes={{
                lang
            }}
            title={title}
            titleTemplate="%s"
            meta={[
                {
                    name: 'description',
                    content: metaDescription
                },
                {
                    property: 'og:title',
                    content: title
                },
                {
                    property: 'og:description',
                    content: metaDescription
                },
                {
                    property: 'og:type',
                    content: 'website'
                },
                {
                    name: 'twitter:card',
                    content: 'summary'
                },
                {
                    name: 'twitter:creator',
                    content: site.siteMetadata.author
                },
                {
                    name: 'twitter:title',
                    content: title
                },
                {
                    name: 'twitter:description',
                    content: metaDescription
                }
            ].concat(meta)}
        >
            {/* <!-- Google Tag Manager --> */}
            <script>{`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-P9LDC55');
      `}</script>

            <script src="https://chatclient.optiwe.com/bundle.min.js" />
            {(locales || []).map((locale) => (
                <link rel="alternate" hrefLang={locale.hreflang} href={locale.href} />
            ))}
        </Helmet>
    )
}

SEO.defaultProps = {
    lang: 'en',
    meta: [],
    description: '',
    locales: []
}

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
    title: PropTypes.string.isRequired,
    locales: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string))
}

export default SEO
