import React from 'react'

const text = {
    es: {
        talk: 'Hablemos!',
        email: 'info@optiwe.com',
        whatsappContact: 'Contacto wn WhatsApp',
        platform: 'Plataforma',
        chatgpt: 'ChatGPT para WhatsApp',
        multiagent: 'WhatsApp Multiagente',
        chatbots: 'WhatsApp Chatbots',
        reports: 'WhatsApp Reportes',
        marketing: 'WhatsApp Marketing',
        resources: 'Recursos',
        help: 'Ayuda',
        blog: 'Blog',
        terms: 'Términos de uso',
        policy: 'Política de privacidad',
        alternatives: 'Alternativas',
        articles: 'Articulos Destacados'
    },
    en: {
        talk: "Let's talk!",
        email: 'info@optiwe.com',
        whatsappContact: 'WhatsApp Contact',
        platform: 'Platform',
        chatgpt: 'ChatGPT for WhatsApp',
        multiagent: 'Shared WhatsApp Inbox',
        chatbots: 'WhatsApp Chatbot',
        reports: 'Reports',
        marketing: 'WhatsApp Marketing',
        resources: 'Resources',
        help: 'Help',
        blog: 'Blog',
        terms: 'Terms of use',
        policy: 'Privacy policy',
        alternatives: 'Alternatives',
        articles: 'Featured Articles'
    }
}

function Footer ({ lang }) {
    if (lang === 'es') {
        return (
            <footer className="container-fluid ow-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 pt-3 pb-3 pt-sm-5 pb-sm-4">
                            <h3>{text[lang].talk}</h3>
                            <p><a href="mailto:info@optiwe.com">{text[lang].email}</a></p>
                            <p><a href="https://wa.me/+5491152351034">{text[lang].whatsappContact}</a></p>
                        </div>
                        <div className="col-md-3 pt-3 pb-3 pt-sm-5 pb-sm-4">
                            <h3>{text[lang].platform}</h3>
                            <p><a href="https://optiwe.com/es/productos/chatgpt-para-ventas/">{text[lang].chatgpt}</a></p>
                            <p><a href="https://optiwe.com/es/productos/whatsapp-multiagente/">{text[lang].multiagent}</a></p>
                            <p><a href="https://optiwe.com/es/productos/chatbot-whatsapp/">{text[lang].chatbots}</a></p>
                            <p><a href="https://optiwe.com/es/productos/marketing-digital-whatsapp/">{text[lang].marketing}</a></p>
                            <p><a href="https://optiwe.com/es/productos/reportes-whatsapp/">{text[lang].reports}</a></p>
                        </div>
                        <div className="col-md-3 pt-3 pb-3 pt-sm-5 pb-sm-4">
                            <h3>{text[lang].resources}</h3>
                            <p><a href="https://soporte.optiwe.com/">{text[lang].help}</a></p>
                            <p><a href="https://optiwe.com/blog/es/">{text[lang].blog}</a></p>
                        </div>
                        <div className="col-md-3 pt-3 pb-3 pt-sm-5 pb-sm-4">
                            <h3>Optiwe | {new Date().getFullYear()} </h3>
                            <p><a href="https://optiwe.com/es/legal/terms/">{text[lang].terms}</a></p>
                            <p><a href="https://optiwe.com/es/legal/privacy/">{text[lang].policy}</a></p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3 pt-3 pb-3 pt-sm-5 pb-sm-4">
                            <h3>{text[lang].alternatives}</h3>
                            <p><a href="https://optiwe.com/blog/es/alternativa-a-chattigo/">Chattigo</a></p>
                            <p><a href="https://optiwe.com/blog/es/alternativa-a-botmaker/">Botmaker</a></p>
                            <p><a href="https://optiwe.com/blog/es/alternativa-a-landbot/">Landbot</a></p>
                            <p><a href="https://optiwe.com/blog/es/alternativa-a-b2chat/">B2Chat</a></p>
                        </div>
                        <div className="col-md-3 pt-3 pb-3 pt-sm-5 pb-sm-4">
                            <h3>{text[lang].alternatives}</h3>
                            <p><a href="https://optiwe.com/blog/es/alternativa-a-liveconnect/">LiveConnect</a></p>
                            <p><a href="https://optiwe.com/blog/es/alternativa-a-sirena/">Sirena</a></p>
                            <p><a href="https://optiwe.com/blog/es/alternativa-a-cliengo/">Cliengo</a></p>
                            <p><a href="https://optiwe.com/blog/es/alternativa-a-callbell/">CallBell</a></p>
                        </div>
                        <div className="col-md-3 pt-3 pb-3 pt-sm-5 pb-sm-4">
                            <h3>{text[lang].articles}</h3>
                            <p><a href="https://optiwe.com/blog/es/chatgpt/">Ventas con ChatGPT</a></p>
                            <p><a href="https://optiwe.com/blog/es/aumenta-tus-ventas-en-whatsapp/">Vender en WhatsApp</a></p>
                            <p><a href="https://optiwe.com/blog/es/whatsapp-business-api/">WhatsApp Business API</a></p>
                            <p><a href="https://optiwe.com/blog/es/whatsapp-chatbot/">Chatbots en WhatsApp</a></p>
                            <p><a href="https://optiwe.com/blog/es/whatsapp-web/">WhatsApp en tu web</a></p>
                        </div>
                    </div>
                </div>
            </footer>
        )
    } else {
        return (
            <footer className="container-fluid ow-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 pt-3 pb-3 pt-sm-5 pb-sm-4">
                            <h3>{text[lang].talk}</h3>
                            <p><a href="mailto:info@optiwe.com">{text[lang].email}</a></p>
                            <p><a href="https://wa.me/+5491152351034">{text[lang].whatsappContact}</a></p>
                        </div>
                        <div className="col-md-3 pt-3 pb-3 pt-sm-5 pb-sm-4">
                            <h3>{text[lang].platform}</h3>
                            <p><a href="https://optiwe.com/products/chatgpt-for-sales/">{text[lang].chatgpt}</a></p>
                            <p><a href="https://optiwe.com/products/shared-whatsapp-inbox/">{text[lang].multiagent}</a></p>
                            <p><a href="https://optiwe.com/products/whatsapp-chatbot/">{text[lang].chatbots}</a></p>
                            <p><a href="https://optiwe.com/products/whatsapp-marketing/">{text[lang].marketing}</a></p>
                            <p><a href="https://optiwe.com/products/whatsapp-reports/">{text[lang].reports}</a></p>

                        </div>
                        <div className="col-md-3 pt-3 pb-3 pt-sm-5 pb-sm-4">
                            <h3>Optiwe | {new Date().getFullYear()} </h3>
                            <p><a href="https://optiwe.com/legal/terms">{text[lang].terms}</a></p>
                            <p><a href="https://optiwe.com/legal/privacy">{text[lang].policy}</a></p>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}
export default Footer
